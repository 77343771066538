<template>
    <div class="ls-box">
        <div class="item" v-for="(item, index) in _files(arr, field)" :key="index">

            <img :src="item.url" alt="" style="display: block;width: 148px;height: 148px;">
            <div class="mask" v-if="cate == 0">
                <a class="el-icon-delete" @click="_del(item.id)"></a>
                <a class="el-icon-zoom-in ml-20" @click="_prev(item)"></a>
            </div>

        </div>
        <el-upload :action="url" list-type="picture-card" :data="form_data" :on-success="_success"
            :show-file-list="false" multiple>
            <i class="el-icon-plus"></i>
        </el-upload>

        <el-dialog :visible.sync="show_prev" append-to-body>
            <img width="100%" :src="prev_url" alt="">
        </el-dialog>

    </div>
</template>
<script>
export default {
    model: {
        prop: "files",
        event: "change",
    },
    props: {
        files: {
            type: Array,
            default() {
                return [];
            }
        },
        table: {},
        field: {
            default: 'pic'
        },
        cate: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            arr: [],
            r: {},
            show: false,
            prev_url: '',
            show_prev: false
        };
    },
    watch: {
        files(val) {
            this.arr = val;
        }
    },
    computed: {
        url() {
            return `${this.$domain}/index.php/file/imgs`;
        },
        form_data() {
            return {

                field: this.field
            };
        }
    },
    created() { },
    mounted() {
        this.arr = this.files;
    },
    methods: {
        _prev(item) {
            this.prev_url = item.url;
            this.show_prev = true;
        },
        _del(id) {
            this.$http.post('file/del', {
                id: id
            }).then(res => {
                if (res.code) {
                    for (let index = 0; index < this.arr.length; index++) {
                        let item = this.arr[index];
                        if (item.id == id) {
                            this.arr.splice(index, 1);
                        }
                    }
                }
            })
        },
        _success(res) {
            this.arr.push(res.data);
            this.$emit('change', this.arr);
        }
    },
};

</script>
<style lang="scss" scoped>
@import "index.scss";
</style>