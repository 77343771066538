<template>
    <div class="ls-upload1">
        <div class="flex" :class="{ disabled: disabled }">
            <el-upload :action="url" auto-upload :show-file-list="false" :on-success="_ok" :data="{ width, height }"
                :disabled="disabled">
                <div class="flex">
                    <el-input v-model="temp" placeholder="选择文件"></el-input>
                    <el-button type="primary">上传</el-button>
                    <a class="el-icon-delete f-18 ml-15" @click.stop="_del" v-if="temp && !disabled"></a>
                </div>
            </el-upload>
            <a class=" el-icon-view f-18 ml-15" @click.stop="_see" v-if="temp"></a>
        </div>
        <!-- <div class="pre" v-if="temp">
            <img :src="temp" alt="">
        </div> -->
    </div>
</template>
<script>
export default {
    props: {
        value: {},
        width: {
            default: 1000
        },
        height: {
            default: 1000
        },
        disabled: {
            default: false,
            type: Boolean
        }
    },
    computed: {
        url() {
            return `${this.$domain}/index.php/file/imgs`;
        }
    },
    data() {
        return {
            temp: this.value
        };
    },
    watch: {
        temp(val) {
            this.$emit('input', val);
        },
        value(val) {
            this.temp = val;
        }
    },
    methods: {
        _see() {
            window.open(this.value);
        },
        _del() {
            this.temp = '';
        },
        _ok(res, file) {
            this.temp = res.data.url;
        }
    }
};
</script>
<style lang="scss" scoped>
@import 'index.scss';
</style>