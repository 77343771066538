import {
    mapState
} from 'vuex'
import store from "@/store/"
// 尝试将用户在根目录中的store/index.js的vuex的state变量，全部加载到全局变量中
let $uStoreKey = [];
try {
    $uStoreKey = store.state ? Object.keys(store.state) : [];
} catch (e) { }
let mixins = {
    data() {
        return {

            r: {},
            show_save: false,
            ids: [],
            cfg: {},
            admin: {},
            mix_fields: []
        };
    },
    computed: {
        ...mapState($uStoreKey),
    },
    created() {
        this.$vuex = (name, value) => {
            this.$store.commit('$uStore', {
                name,
                value
            })
        };
    },
    methods: {
        _num_for(num) {
            num = (num / 10000).toFixed(2);
            return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        _del_more() {
            this.$http.post(`${this.table}/del_more`, {
                ids: this.ids
            }).then(res => {
                if (res.code) {
                    this._load(1);
                }
            })
        },
        _del_all() {
            this.$confirm(`确定清空所有吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(`${this.table}/del_all`).then(res => {
                    if (res.code) {
                        this._load(1);
                    }
                })
            }).catch(() => {
            });
        },
        _remove_html(input) {
            return input.replace(/<[^>]*>/g, '');
        },
        _vuex(name, val) {
            console.log(name, val);
            this.$vuex(`vuex_${name}`, val);
            console.log(this[`vuex_${name}`]);
        },
        _reset(field) {
            this[field] = this.$options.data()[field];
        },
        _time_date(time) {
            time = Math.abs(time * 1000);
            let SECOND = 1000
            let MINUTE = 60 * SECOND
            let HOUR = 60 * MINUTE
            let DAY = 24 * HOUR
            let days = Math.floor(time / DAY) || 0
            let hours = Math.floor((time % DAY) / HOUR) || 0
            let minutes = Math.floor((time % HOUR) / MINUTE) || 0;
            let str = '';
            if (days) {
                str = days + '天前';
            } else if (hours) {
                str = hours + '小时前';
            } else if (minutes) {
                str = minutes + '分钟前';
            } else {
                str = '刚刚';
            }
            return str;
        },
        _table_time(row, column, cellValue, index) {
            // console.log(row, column, cellValue, index);
            return this._time_format(cellValue);
        },
        _time_format(dateTime = null, fmt = 'yyyy-mm-dd hh:MM:ss') {
            if (!dateTime) {
                return '-';
            }
            // 如果为null,则格式化当前时间
            // if (!dateTime) dateTime = Number(new Date())
            // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
            if (dateTime.toString().length == 10) dateTime *= 1000
            const date = new Date(dateTime)
            let ret
            const opt = {
                'y+': date.getFullYear().toString(), // 年
                'm+': (date.getMonth() + 1).toString(), // 月
                'd+': date.getDate().toString(), // 日
                'h+': date.getHours().toString(), // 时
                'M+': date.getMinutes().toString(), // 分
                's+': date.getSeconds().toString() // 秒
                // 有其他格式化字符需求可以继续添加，必须转化成字符串
            }
            for (const k in opt) {
                ret = new RegExp(`(${k})`).exec(fmt)
                if (ret) {
                    fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
                }
            }
            return fmt
        },
        _msg(msg) {
            this.$message.success(msg);
        },
        _msg0(msg) {
            this.$message.error(msg);
        },
        _to_url(url) {
            this.$router.push(url);
        },
        _admin(data = {}) {
            return new Promise(s => {
                this.$http.post('admin/my', data).then(res => {
                    this.admin = res.data;
                    s(res);
                })
            });
        },
        _quick_edit(table, id, field, value) {
            return new Promise(s => {
                this.$http.post('index/quick_edit', {
                    table: table,
                    id: id,
                    field: field,
                    value: value
                }).then(res => {
                    s(res);
                })
            })
        },
        _quick_confirm(table, id, field, value) {
            return new Promise(s => {
                this.$confirm('确定操作吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.post('index/quick_edit', {
                        table: table,
                        id: id,
                        field: field,
                        value: value
                    }).then(res => {
                        s(res);
                    })
                }).catch(() => { });
            })
        },
        _files(files, field = '') {
            if (files == undefined) {
                return [];
            }
            let res = [];
            for (let i = 0; i < files.length; i++) {
                if (files[i].field == field) {
                    res.push(files[i]);
                }
            }
            return res;
        },
        _load(type = 0) {
            if (type == 1) {
                this.datas.page = 0;
            }
            if (this.datas.status != 0) {
                return;
            }
            this.datas.status = -1;
            let http = this.datas.url || `${this.table}/list`;
            if (!http) {
                return;
            }
            let page = this.datas.page;
            this.$http.post(http, {
                page,
                order: this.datas.order,
                ...this.datas.add,
                limit: this.datas.limit,
            }).then(res => {
                this.datas.status = 0;
                let add = res.data.data || [];
                this.datas.data = add;
                this.datas.total = res.data.total || 0;
                this.datas.page = res.data.current_page;
            });
        },
        _config(fields = []) {

            return new Promise(s => {
                this.$http.post('config/index', {
                    fields: fields
                }).then(res => {
                    this.cfg = res.data;
                    s(res);
                });

            })

        },
        _save(id = 0, data = {}) {
            // 这里重要 否则 先编辑 后添加有问题
            this._reset('r');
            this.show_save = true;
            if (id > 0) {
                this.$http.post(`${this.table}/show`, {
                    id: id,
                    ...data
                }).then(res => {
                    if (res.code) {
                        this.r = {
                            ...data,
                            ...res.data
                        }
                        this.r = res.data;
                    }
                });
            } else {
                this.r = {
                    ...this.r,
                    ...data
                };
            }
        },
        _read(id, data = {}, table = '') {
            this.$http.post(`${this.table}/show`, {
                id,
                ...this.r_add || {}
            }).then(res => {
                this.r = {
                    ...this.$options.data().r,
                    ...res.data,
                    ...data
                };
            });
        },
        _submit(opt = {}) {
            if (opt.is_val) {
                var val = true;
                this.$refs.r.validate(validate => {
                    if (validate) {
                        val = true;
                    } else {
                        val = false;
                    }
                })
            } else {
                val = true;
            }
            if (val) {
                let action = 'add';
                if (this.r.id) {
                    action = 'edit';
                }
                this.$http.post(`${this.table}/${action}`, {
                    ...this.r,
                    ...this.r_add
                })
                    .then(res => {
                        if (res.code) {
                            this.show_save = false;
                            this.r = this.$options.data().r;
                            this._load();
                        }
                    });
            }
        },
        _del(id = 0) {
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(`${this.table}/del`, {
                    id: id
                }).then(res => {
                    this._load();
                });
            }).catch(() => {

            });
        },
        _select(val) {
            this.ids = [];
            for (let i = 0; i < val.length; i++) {
                this.ids.push(val[i].id);
            }
        },
        _size(val) {
            this.datas.limit = val;
            this._load(1);
        },
        _page(val) {
            this.datas.page = val;
            this._load();
        },
        _sort(obj) {
            let type = "desc";
            if (obj.order == "ascending") {
                type = "asc";
            }
            var key = obj.prop;
            if (obj.column.sortBy !== undefined) {
                key = obj.column.sortBy;
            }
            if (this.datas.order[key]) {
                delete this.datas.order[key];
            }
            this.datas.order[key] = type;
            let arr = [];
            for (let i in this.datas.order) {
                arr.push(i);
            }
            arr.reverse();
            let new_order = {};
            arr.forEach((item, index) => {
                new_order[item] = this.datas.order[item];
            });
            this.datas.order = new_order;
            console.log(this.datas.order);
            this._load(1);
        },
        // 影藏字符串
        _hide_str(str, frontLen, endLen) {
            var newStr;
            var len = str.length - frontLen - endLen;
            if (str.length === 2) {
                newStr = str.substring(0, 1) + '*';
            } else if (str.length > 2) {
                var char = '';
                for (let i = 0; i < len; i++) {
                    char += '*';
                }
                newStr = str.substring(0, frontLen) + char + str.substring(str.length - endLen);
            } else {
                newStr = str;
            }
            return newStr;
        },
    }
}
export default mixins
