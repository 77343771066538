export default {
    data() {
        return {
            code: "",
            codes: "0123456789abcdwerwshdje",
            r: {
                plat: 'Android',
                code: ''
            },
            is_done: false,
            info: {}
        }
    },
    created() {
        setTimeout(() => {
            this._recode();
        }, 1000);
        this._config(['app_name', 'fav', 'banner']).then(res => {
            document.title = res.data.app_name;
            const link = document.createElement('link');
            link.rel = 'icon';
            link.href = res.data.fav;
            document.head.appendChild(link);
        })
    },
    methods: {
        _ok() {
            location.reload();
        },
        _submit() {
            if (this.r.code != this.code || !this.r.code) {
                this._msg0('验证码错误');
                return;
            }
            this.$http.post('ma/dh', {
                ...this.r
            }).then(res => {
                if (res.code) {
                    this.info = res.data;
                    this.is_done = true;
                } else {
                    this._recode();
                }
            })
        },
        _recode() {
            this.code = "";
            this.r.code = '';
            this._code(this.codes, 4);
        },
        _rnum(min, max) {
            max = max + 1
            return Math.floor(Math.random() * (max - min) + min)
        },
        _code(data, len) {
            for (let i = 0; i < len; i++) {
                this.code += data[this._rnum(0, data.length - 1)]
            }
        },
    }
}