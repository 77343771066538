import axios from 'axios';
import self from '@/main.js';
import {
    Message
} from 'element-ui';
let $http = axios.create({
    timeout: 500000,
    withCredentials: true, // 允许携带cookie
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    },
});
// 添加请求拦截器
$http.interceptors.request.use(function (config) {
    if (!config.baseURL) {
        config.baseURL = self.$domain + '/api.php';
    }
    config.headers['token'] = sessionStorage.getItem("vuex_token");
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});
// 添加响应拦截器
$http.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    let res = response.data;

    if (res.msg) {
        Message({
            message: res.msg,
            type: res.code ? 'success' : 'error'
        })
    }

    return Promise.resolve(response.data);
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});
export default $http
