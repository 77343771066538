<template>
    <div class="flex">

        <el-date-picker v-model="value_temp" type="datetimerange" range-separator="至" start-placeholder="开始时间"
            end-placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
        <!-- <el-radio-group v-model="type">
            <el-radio-button :label="1">
                本周
            </el-radio-button>
            <el-radio-button :label="2">
                上周
            </el-radio-button>
            <el-radio-button :label="3">
                本月
            </el-radio-button>
            <el-radio-button :label="4">
                上月
            </el-radio-button>
        </el-radio-group> -->
    </div>
</template>
<script>
    export default {
        props: {
            value: {
                type: Array,
                default() {
                    return [];
                }
            },
        },
        data() {
            return {
                type: '',
                value_temp: []
            }
        },
        watch: {
            type(val) {
                if (val) {
                    this._date(val);
                }
            },
            value_temp(val) {
                this.$emit('input', val);
            },
            value(val) {
                this.value_temp = val;
            }
        },
        methods: {
            //格式化日期：yyyy-MM-dd 
            formatDate(date) {
                var myyear = date.getFullYear();
                var mymonth = date.getMonth() + 1;
                var myweekday = date.getDate();
                if (mymonth < 10) {
                    mymonth = "0" + mymonth;
                }
                if (myweekday < 10) {
                    myweekday = "0" + myweekday;
                }
                return (myyear + "-" + mymonth + "-" + myweekday);
            },
            _date(type) {
                var now = new Date(); //当前日期 
                var nowDayOfWeek = now.getDay(); //今天本周的第几天 
                var nowDay = now.getDate(); //当前日 
                var nowMonth = now.getMonth(); //当前月 
                var nowYear = now.getYear(); //当前年 
                nowYear += (nowYear < 2000) ? 1900 : 0; //
                var lastMonthDate = new Date(); //上月日期
                lastMonthDate.setDate(1);
                lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
                var lastYear = lastMonthDate.getYear();
                var lastMonth = lastMonthDate.getMonth();
                let res = [0, 0];
                switch (type) {
                    case 1://本周
                        res[0] = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek - 1);
                        res[1] = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek) - 1);
                        break;
                    case 2://上周
                        res[0] = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek - 6);
                        res[1] = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek) - 6);
                        break;
                    case 3://本月
                        res[0] = new Date(nowYear, nowMonth, 1);
                        res[1] = new Date(nowYear, nowMonth, nowDay);
                        break;
                    case 4://上月
                        res[0] = new Date(nowYear, lastMonth, 1);
                        var lastMonthStartDate = new Date(nowYear, lastMonth, 1);
                        var lastMonthEndDate = new Date(nowYear, lastMonth + 1, 1);
                        var days = (lastMonthEndDate - lastMonthStartDate) / (1000 * 60 * 60 * 24);//格式转换
                        res[1] = new Date(nowYear, lastMonth, days);
                        break;
                }
                let add = ' 00:00:00';
                let res1 = res.map((item, index) => {
                    if (index == 1) {
                        add = ' 23:59:59';
                    }
                    return this.formatDate(item) + add;
                });
                this.$emit('input', res1);
            },
        }
    };
</script>
<style lang="scss" scoped>
    @import "index.scss";
</style>