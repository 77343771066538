<template>
    <div id="app">
        <router-view :key="key"></router-view>
        <div class="footer">
            <p style="text-align: center; font-size: 13px;">
                <span style="color: rgb(206, 212, 217);">抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当
                    适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</span>
            </p>
            <p style="text-align: center;">
                <span style="color: rgb(206, 212, 217); font-size: 13px;">适龄提示：适合16岁（含）以上玩家娱乐
                    Copyright@2021-2024 all rights reserved</span>
            </p>
            <p style="text-align: center;">
                <span style="color: rgb(206, 212, 217); font-size: 13px;">游戏许可及服务协议 ｜游戏隐私保护指引</span>
            </p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
        };
    },
    computed: {
        key() {
            return this.$route.fullPath + new Date();
        },
    },
    created() {
    },
    mounted() {
    },
    methods: {
    },
};
</script>
<style lang="scss">
@import "@/css/style.scss";
</style>
<style lang="scss" scoped>
.footer {
    background: #000;
    padding: 15px;
    font-size: 14px;
    line-height: 24px;
}
</style>