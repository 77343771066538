<template>
    <div>
        <div>
            <ls-filter :table="table" @change="(e) => {
                datas.add = {
                    ...datas.add,
                    ...e,
                };
            }
                ">
                <el-button type="primary" @click="_load(1)">搜索</el-button>
                <el-button type="primary" icon="el-icon-circle-plus-outline" @click="_save(0)">添加</el-button>
            </ls-filter>
        </div>
        <el-table v-loading="datas.status == -1" :data="datas.data" @sort-change="_sort" @selection-change="_select">
            <el-table-column prop="id" label="ID" width="100" sortable="custom"></el-table-column>

            <template v-for="(item, index) in mix_fields">
                <el-table-column :prop="item.COLUMN_NAME" :label="item.COLUMN_COMMENT" :width="item.width"
                    v-if="item.is_show == 1" :key="index" :align="item.align || 'center'">
                    <template slot-scope="scope">
                        {{ scope.row[item.COLUMN_NAME] }}
                    </template>
                </el-table-column>
            </template>


            <el-table-column width="150" label="操作">
                <template slot-scope="scope">
                    <div class="handle-box">
                        <el-button @click="_save(scope.row.id)">编辑</el-button>
                        <el-button @click="_del(scope.row.id)" class="c-danger">删除</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="page-box">
            <el-pagination @size-change="_size" @current-change="_page" :current-page="datas.page"
                :page-sizes="[10, 20, 50, 100]" :page-size="datas.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="datas.total">
            </el-pagination>
        </div>
        <ls-mask v-if="show_save" @close="show_save = false" width="600px">
            <el-form>
                <el-form-item label="名称">
                    <el-input v-model="r.title"></el-input>
                </el-form-item>

                <el-form-item label="图片">
                    <ls-upload v-model="r.pic"></ls-upload>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="_submit">提交</el-button>
                </el-form-item>
            </el-form>
        </ls-mask>
        <ls-table-data :table="table" @fields="(e) => {
            mix_fields = e;
        }
            "></ls-table-data>
    </div>
</template>
<script>
export default {
    data() {
        return {
            table: "",
            datas: {
                order: {
                    id: 'desc'
                }
            }
        };
    },
    created() {
        this._load();
    },
    methods: {},
};
</script>
<style lang="scss"></style>