import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default Vue.extend({
    props: {
        value: {
            default: ''
        }
    },
    components: { Editor, Toolbar },
    data() {
        return {
            editor: null,
            html: this.value,
            toolbarConfig: {},
            editorConfig: {
                placeholder: '请输入内容...',
                MENU_CONF: {
                    uploadImage: {
                        server: `${this.$domain}/index.php/file/editor`,
                        fieldName: 'file',


                    }
                }
            },
            mode: 'simple', // or 'simple'
        }
    },
    watch: {
        html(val) {
            this.$emit('input', val);
        },
        value(val) {
            this.html = val;
        }
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
    },
    mounted() {
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
})