import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.config.productionTip = false
import ElementUI from 'element-ui';
import './element-variables.scss'
ElementUI.Dialog.props.width = {
    default() {
        return '50%';
    }
};
ElementUI.Form.props.labelWidth = {
    default() {
        return '120px';
    }
};

ElementUI.TableColumn.props.align = {
    default() {
        return 'center';
    }
};
ElementUI.TableColumn.props.showOverflowTooltip = {
    default() {
        return true;
    }
};
ElementUI.Table.props.border = {
    default() {
        return true;
    }
};
console.log(ElementUI.Table.props);
Vue.use(ElementUI, {
    size: 'medium',
    zIndex: 999999
});



Vue.prototype.$domain = 'https://dj.hndlyf.cn';
import $http from '@/js_sdk/http.js'
Vue.prototype.$http = $http
import mixins from './mixins/'
Vue.mixin(mixins);





// 自动组件
const requireComponent = require.context(
    // 其组件目录的相对路径
    './components',
    // 是否查询其子目录
    true,
    // 匹配基础组件文件名的正则表达式
    /ls[A-Z]\w+\/index.(vue|js)$/
)
requireComponent.keys().forEach(fileName => {
    console.log(fileName, '1111');
    let name = fileName.split('/');
    const componentConfig = requireComponent(fileName)
    const componentName = name[1];
    console.log(componentName);
    Vue.component(
        componentName,
        // 如果这个组件选项是通过 `export default` 导出的，
        // 那么就会优先使用 `.default`，
        // 否则回退到使用模块的根。
        componentConfig.default || componentConfig
    )
})
// 自动组件
// router.beforeEach((to, from, next) => {
//     if (sessionStorage.getItem("admin_id")) {
//         next();
//     } else {
//         if (to.path == '/login') {
//             next();
//         } else {
//             next('/login');
//         }
//     }
// })
const app = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
export default app
