export default {
    props: {
        value: {
            type: Array,
            default() {
                return [];
            }
        },
        field: {
            default: 'pic'
        },
        width: {
            default: '400px'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            percent: 0,
            arr: [...this.value],
        };
    },
    watch: {
        value(val) {
            this.arr = [...val];
        },
    },
    computed: {
        url() {
            return `${this.$domain}/index.php/file/imgs`;
        },
        form_data() {
            return {
                field: this.field,

            };
        }
    },
    methods: {
        _ing(e, file, file_list) {
            console.log(e);
            this.percent = Math.floor(e.percent);
        },
        _del(id) {
            this.$http.post('file/del', {
                id: id
            }).then(res => {
                if (res.code) {
                    for (let index = 0; index < this.arr.length; index++) {
                        let item = this.arr[index];
                        if (item.id == id) {
                            this.arr.splice(index, 1);
                            this.$emit('input', this.arr || []);
                        }
                    }
                }
            })
        },
        _success(res, file) {

            res.data.file_name = file.name;
            this.arr.push(res.data);
            this.$emit('input', this.arr);
        }
    },
};